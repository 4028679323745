
// import ProjectBlock from './Blocks/ProjectBlock.vue'

export default {
  name: 'PortfolioComponent',
  components: {
    // ProjectBlock
  },
  data () {
    return {
      projects: [{
        id: 1,
        title: 'example.cz',
        description: 'Lorem Ipsum dolor sit amet',
        thumbnail: 'img/portfolio/skala.png',
        imgUrl: 'test'
      },
      {
        id: 2,
        title: 'example.cz',
        description: 'Lorem Ipsum dolor sit amet',
        thumbnail: 'img/portfolio/skala.png',
        imgUrl: 'test'
      }
      ]
    };
  }
};
