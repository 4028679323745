import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import VueSmoothScroll from 'vue3-smooth-scroll';
import VueGtag from 'vue-gtag-next';
import '@popperjs/core';
import 'bootstrap';

const app = createApp(App);
app.use(VueSmoothScroll, {
  duration: 500
});
app.use(VueGtag, {
  property: {
    id: 'UA-129580924-1'
  }
});
app.mount('#app');
