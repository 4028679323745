

export default {
  name: 'AboutComponent',
  data () {
    return {
      languages: [{
        value: 'HTML'
      }, {
        value: 'CSS'
      }, {
        value: 'PHP'
      }, {
        value: 'SQL'
      }]
    };
  }
};
