
export default {
  name: 'NavbarComponent',
  mounted () {
    document.addEventListener('scroll', (this as any).navbarCollapse);
  },
  unmounted () {
    document.removeEventListener('scroll', (this as any).navbarCollapse);
  },
  methods: {
    navbarCollapse (): void {
      const el = document.getElementById('mainNav');
      if (el === null) {
        console.error('navbarCollapse error');
        return;
      }

      if (window.scrollY > 20) {
        el.classList.add('navbar-shrink');
      } else {
        el.classList.remove('navbar-shrink');
      }
    }
  }
};
