
/**
 * @todo Modals smart cycle.
 */
import NavbarComponent from './components/NavbarComponent.vue';
import MastheadComponent from './components/MastheadComponent.vue';
import PortfolioComponent from './components/PortfolioComponent.vue';
import ModalsComponent from './components/ModalsComponent.vue';
import AboutComponent from './components/AboutComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
import CopyrightComponent from './components/CopyrightComponent.vue';

export default {
  name: 'App',
  components: {
    NavbarComponent,
    MastheadComponent,
    PortfolioComponent,
    ModalsComponent,
    AboutComponent,
    FooterComponent,
    CopyrightComponent
  }
};
