import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavbarComponent = _resolveComponent("NavbarComponent")!
  const _component_MastheadComponent = _resolveComponent("MastheadComponent")!
  const _component_PortfolioComponent = _resolveComponent("PortfolioComponent")!
  const _component_ModalsComponent = _resolveComponent("ModalsComponent")!
  const _component_AboutComponent = _resolveComponent("AboutComponent")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!
  const _component_CopyrightComponent = _resolveComponent("CopyrightComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavbarComponent),
    _createVNode(_component_MastheadComponent),
    _createVNode(_component_PortfolioComponent),
    _createVNode(_component_ModalsComponent),
    _createVNode(_component_AboutComponent),
    _createVNode(_component_FooterComponent),
    _createVNode(_component_CopyrightComponent)
  ], 64))
}