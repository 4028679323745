import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "btn btn-outline-light btn-social mx-1",
    target: "_blank",
    href: $props.url
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(["fab fa-fw", $props.serviceClass])
    }, null, 2)
  ], 8, _hoisted_1))
}