

export default {
  name: 'CopyrightComponent',
  data () {
    return {
      year: new Date().getFullYear()
    };
  }
};
